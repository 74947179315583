import React, { useEffect, useState } from 'react'
import houseServices from '../../services/HousesService'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import HouseItem from '../houses/HouseItem'
import EmptyList from '../feedback/EmptyList'

function HousesList() {
    const [houses, setHouses] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    
    useEffect(() => {
        setError()
        houseServices.getHouses('').then(
            res => {
                setHouses(res.data)
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }, [])
    useEffect(() => {}, [houses])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    return (
        <div className='bg-purple-100 py-20  p-3'>
            <div className="container mx-auto">
                <h2 className="text-primary uppercase font-semibold tracking-wider mb-6">Architectural Plans</h2>
                <p className='text-xl md:text-2xl text-secondary mb-6 font-bold'>Best Selling Architectural Plans</p>
                <p className='mb-8'>These are the best selling plans and most recommended designs for you to choose from.</p>
                {loading ? (
                    <Loading/>
                ):(
                    <div>
                        {error ? (
                            <FetchError error={error}/>
                        ):(
                            <div>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                                    {houses?.results?.map((house, idx) => (
                                        <HouseItem
                                            key={idx}
                                            house={house}
                                        />
                                    ))}
                                </div>
                                {houses && (
                                    <div>
                                        {houses?.results?.length < 1 && (
                                            <EmptyList item={"House List"}/>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default HousesList