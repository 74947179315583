import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import Hero from '../components/home/Hero'
// import About from '../components/home/About'
import Categories from '../components/home/Categories'
import BlogListHome from '../components/home/BlogList'
import Contacts from '../components/home/Contacts'
import ConstructionSection from '../components/home/ConstructionSection'
import HousesList from '../components/home/HousesList'

function HomePage(props) {
    const url = window.location.href
    function openModal(){
        const openBtn = document.getElementById("contact-modal-open-btn")
        openBtn.click()
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Home - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            
            <div className=''>
                <Hero/>
            </div>
            <div className='bg-secondary text-center py-6 text-white p-2'>
                <p className='text-2xl md:text-3xl font-bold'>Every great project begins with a trusted partner, let’s build together.</p>
            </div>
            <div className="mb-16">
                <ConstructionSection openModal={openModal}/>
            </div>
            <div className="mb-16">
                <Categories/>
            </div>
            <div className="mb-16">
                <HousesList/>
            </div>
            <div className="mb-16">
                <BlogListHome/>
            </div>
            <div className="mb-16 container mx-auto">
                <Contacts/>
            </div>
            
        </HelmetProvider>
    )
}

export default HomePage