import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function HouseItem(props) {
    const house = props.house
    useEffect(() => {}, [house])
    return (
        <div 
            className={
                props.isHor ? 'card-compact rounded-md border card hover:shadow-lg bg-base-200 w-80'
                :
                'card-compact rounded-md border card hover:shadow-lg bg-base-200'}>
            <Link to={`/house/${house?.slug}`}>
                <img className='rounded-t-md' src={house?.image} alt={house?.name} />
            </Link> 
            <div className="card-body">
                <Link to={`/house/${house?.slug}`}>
                    <p className="font-bold text-secondary">{house?.name}</p>
                </Link>
                <div className="grid grid-cols-2 text-sm font-bold text-black">
                    <div className="flex border-b border-r p-2 pl-0 gap-2">
                        <span>Bedrooms:</span>
                        <span className="text-secondary">{house?.bedrooms || '-'}</span>
                    </div>
                    <div className="flex  border-b  p-2 gap-2">
                        <span>Baths:</span>
                        <span className="text-secondary">{house?.bathrooms || '-'}</span>
                    </div>
                    <div className="flex  p-2 pl-0 border-r gap-2">
                        <span>Story:</span>
                        <span className="text-secondary">{house?.stories || '-'}</span>
                    </div>
                    <div className="flex  p-2  gap-2">
                        <span>Area:</span>
                        <span className="text-secondary">{house?.square_meters || '-'} sq.ft</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HouseItem