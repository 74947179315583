import React, { useEffect, useRef, useState } from 'react'
import Loading from '../feedback/Loading'
import houseServices from '../../services/HousesService'
import toast from 'react-hot-toast';
import utils from '../../utils';

function HouseMediaCreateModal(props) {
    const closeBtn = document.getElementById("house-media-create-modal-close-btn")
    // const [isChecked, setIsChecked] = useState(false)
    const imageRef = useRef()
    const [file, setFile] = useState()
    const [loading, setLoading] = useState(false)
    const house = props.house
    const updateHouse = props.updateHouse
    const action = props.action

    useEffect(() => {}, [file])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [updateHouse])
    useEffect(() => {}, [action])
    // useEffect(() => {
    //     setIsChecked(false)
    // }, [house])

    // function handleCheck(e){
    //     setIsChecked(e.target.checked)
    // }
    function imageChanged(event){
        setFile(event.target.files[0])
    }
    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        if (action === 1) {
            console.log(house?.id);
            formData.append("house", house?.id)
        } else{
            console.log(updateHouse?.id);
            formData.append("house", updateHouse?.id)
        }
        // formData.append("show_premium", isChecked)
        if (file){
            formData.append("file", file)
        }
        houseServices.createHouseMedia(formData).then(
            res => {
                toast.success("House Media Added successfully")
                setLoading(false)
                closeBtn.click()
                props.addHouseMedia(res.data)
                setFile()
                imageRef.current.value = null
            },
            err => {
                setLoading(false)
                toast.error(utils.toasterError)
            }
        )
    }
    function showErrorMsg(){
        toast.error("Please set or save a House first")
        const closeBtn = document.getElementById('house-media-create-modal-close-btn')
        closeBtn.click()
    }
    function checkHouse(){
        if (action === 1 && !house) {
            showErrorMsg()
            return
        }
        if (action === 2 && !updateHouse) {
            showErrorMsg()
            return
        }
    }
    return (
        <div>
            <label 
                onClick={() => checkHouse()}
                htmlFor="house-media-create-modal" id='house-media-create-open-btn' className="btn btn-neutral">Add House Media</label>
            <input type="checkbox" id="house-media-create-modal" className="modal-toggle" />
                <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="house-media-create-modal" id='house-media-create-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3 text-gray-700  mb-5'>Add House Media</h2>
                    <form onSubmit={submitHandler}> 
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Image:*</span>
                            </label>
                            <input 
                                type="file" 
                                ref={imageRef} 
                                required
                                id='file-input' 
                                onChange={imageChanged}
                                className="file-input text-gray-600 file-input-bordered file-input-success w-full max-w-xs" />
                        </div>
                        {/* <div className="form-control mb-5">
                            <label className="label">
                                    <span className="label-text">Show Only Paid Users: (If checked, it'll be visible to only Paid users)</span>
                            </label>
                            <input 
                                // defaultChecked={}
                                onChange={(e) => handleCheck(e)}
                                type="checkbox" 
                                className="checkbox"/>
                        </div> */}
                        <div className="flex justify-center mb-6">
                        {loading ? (
                            <Loading/>
                        ):(
                            <button type="submit" className='btn btn-secondary'>Submit</button>
                        )}
                    </div>
                    </form>
                </div>
                </div>
        </div>
    )
}

export default HouseMediaCreateModal